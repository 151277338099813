/* eslint-disable max-len */
import React from 'react'

import { createSvgIcon } from '../../utils'

// has to be done this way because logo in header loses reference to display:none gradient
// also more optimal for element count
export function DanasHrLogoGradient() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 0 0" width="0" height="0">
            <defs>
                <linearGradient
                    id="danashrbackgroundgradient"
                    x1="0"
                    y1="18"
                    x2="150"
                    y2="18"
                    gradientUnits="userSpaceOnUse">
                    <stop offset="0" stopColor="#e2002e" />
                    <stop offset="50%" stopColor="#c9031f" />
                    <stop offset="99%" stopColor="#a5242e" />
                </linearGradient>
                <linearGradient
                    id="danashrglowgradient"
                    x1="72.79"
                    y1="1.22"
                    x2="72.94"
                    y2="18.9"
                    gradientUnits="userSpaceOnUse">
                    <stop offset="0" stopColor="#fff" stopOpacity="0%" />
                    <stop offset="99%" stopColor="#fff" />
                </linearGradient>
            </defs>
        </svg>
    )
}

const DanasHrLogo = createSvgIcon(
    <>
        <rect
            id="danasBg"
            className="f"
            x="0"
            width="145.74"
            height="37.35"
            rx="2.22"
            ry="2.22"
            fill="url(#danashrbackgroundgradient) #c9031f"
            strokeWidth={0}
        />
        <path
            className="h"
            fill="#fff"
            strokeWidth={0}
            d="M26,6.81c2.19,1.13,3.88,2.73,5.07,4.81,1.19,2.08,1.79,4.5,1.79,7.25s-.6,5.15-1.79,7.21c-1.19,2.06-2.88,3.65-5.07,4.77-2.19,1.13-4.72,1.69-7.6,1.69h-9.58V5.13h9.58c2.88,0,5.41.56,7.6,1.69ZM24.88,25.51c1.57-1.57,2.36-3.78,2.36-6.64s-.79-5.09-2.36-6.7-3.8-2.42-6.68-2.42h-3.89v18.11h3.89c2.88,0,5.11-.79,6.68-2.36Z"
        />
        <path
            className="h"
            fill="#fff"
            strokeWidth={0}
            d="M53.01,27.32h-10.92l-1.81,5.22h-5.77l9.86-27.46h6.4l9.86,27.46h-5.81l-1.81-5.22ZM51.51,22.92l-3.97-11.47-3.97,11.47h7.93Z"
        />
        <path
            className="h"
            fill="#fff"
            strokeWidth={0}
            d="M86.98,32.54h-5.5l-12.45-18.81v18.81h-5.5V5.09h5.5l12.45,18.85V5.09h5.5v27.46Z"
        />
        <path
            className="h"
            fill="#fff"
            strokeWidth={0}
            d="M108.43,27.32h-10.92l-1.81,5.22h-5.77l9.86-27.46h6.4l9.86,27.46h-5.81l-1.81-5.22ZM106.94,22.92l-3.97-11.47-3.97,11.47h7.93Z"
        />
        <path
            className="h"
            fill="#fff"
            strokeWidth={0}
            d="M123.26,31.84c-1.53-.65-2.74-1.6-3.63-2.83-.89-1.23-1.35-2.68-1.37-4.36h5.89c.08,1.13.48,2.02,1.2,2.67.72.65,1.71.98,2.97.98s2.29-.31,3.02-.92c.73-.62,1.1-1.42,1.1-2.42,0-.81-.25-1.48-.75-2-.5-.52-1.12-.94-1.87-1.24s-1.77-.63-3.08-1c-1.78-.52-3.23-1.04-4.34-1.55-1.11-.51-2.07-1.28-2.87-2.32-.8-1.03-1.2-2.42-1.2-4.14,0-1.62.41-3.04,1.22-4.24.81-1.2,1.95-2.13,3.42-2.77s3.14-.96,5.03-.96c2.83,0,5.13.69,6.89,2.06s2.74,3.29,2.93,5.75h-6.05c-.05-.94-.45-1.72-1.2-2.34-.75-.62-1.73-.92-2.96-.92-1.07,0-1.93.27-2.57.83-.64.55-.96,1.35-.96,2.4,0,.73.24,1.34.73,1.83.48.49,1.09.88,1.81,1.18.72.3,1.73.65,3.04,1.04,1.78.52,3.23,1.05,4.36,1.57,1.13.52,2.09,1.31,2.91,2.36.81,1.05,1.22,2.42,1.22,4.12,0,1.47-.38,2.83-1.14,4.08-.76,1.26-1.87,2.26-3.34,3-1.47.75-3.21,1.12-5.22,1.12-1.91,0-3.63-.33-5.17-.98Z"
        />
        <path
            className="i colorme"
            fill="#052332"
            strokeWidth={0}
            d="M150.46,32.04c-.64-.62-.96-1.38-.96-2.3s.32-1.68.96-2.3c.64-.62,1.46-.92,2.45-.92s1.77.31,2.4.92.94,1.38.94,2.3-.31,1.68-.94,2.3-1.43.92-2.4.92-1.81-.31-2.45-.92Z"
        />
        <path
            className="i colorme"
            fill="#052332"
            strokeWidth={0}
            d="M176.56,11.69c1.28.72,2.28,1.78,3,3.18.72,1.4,1.08,3.08,1.08,5.05v12.77h-5.5v-12.02c0-1.73-.43-3.06-1.3-3.99s-2.04-1.39-3.54-1.39-2.72.46-3.59,1.39-1.32,2.26-1.32,3.99v12.02h-5.5V3.62h5.5v10.02c.71-.94,1.65-1.68,2.83-2.22,1.18-.54,2.49-.8,3.93-.8,1.65,0,3.12.36,4.4,1.08Z"
        />
        <path
            className="i colorme"
            fill="#052332"
            strokeWidth={0}
            d="M193.36,11.59c1.14-.65,2.44-.98,3.91-.98v5.77h-1.45c-1.73,0-3.03.41-3.91,1.22-.88.81-1.32,2.23-1.32,4.24v10.84h-5.5V10.93h5.5v3.38c.71-1.15,1.63-2.06,2.77-2.71Z"
        />
        <path
            id="e"
            className="g"
            strokeWidth={0}
            opacity={0.32}
            fill="url(#danashrglowgradient) #fff"
            d="M2.22.02h141.3c1.23,0,2.22.99,2.22,2.22v17.46H0V2.24C0,1.01.99.02,2.22.02Z"
        />
    </>,
    'DanasHrLogo',
    '0 0 200 40'
)

export default DanasHrLogo
